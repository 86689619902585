'use client'

import { applyStylesIf, cx } from 'utils/cx'

import { ButtonLink } from './ButtonLink'
import { IconButton, type IconButtons } from './IconButton'

import styles from './Button.module.scss'

enum ButtonType {
	BUTTON = 'button',
	SUBMIT = 'submit',
}

type WidthUnit = '%' | 'px' | 'rem' | 'vw'
type WidthProp = `${number}${WidthUnit}`

export interface ButtonProps<T> {
	border?: boolean
	buttonAttributes?: React.ComponentProps<'button'>
	children?: React.ReactNode
	className?: string
	'data-testid'?: string
	disabled?: boolean
	icon?: IconButtons
	iconPressed?: IconButtons
	id?: string
	inverse?: boolean
	isLink?: boolean
	isSubmit?: boolean
	label?: string
	linkAttributes?: Omit<T, 'children'>
	linkAs?: React.ComponentType<T>
	loading?: boolean
	onClick?: (event: React.MouseEvent) => void
	onKeyDown?: (event: React.KeyboardEvent) => void
	onKeyUp?: (event: React.KeyboardEvent) => void
	onMouseDown?: (event: React.MouseEvent) => void
	onMouseUp?: (event: React.MouseEvent) => void
	secondary?: boolean
	/** 'fill' or the fixed width with measure like '10vw' or '50px'. Default: undefined */
	width?: 'fill' | WidthProp
}

/**
 * @deprecated use specific button types instead: 'fukku/Button/Primary', 'fukku/Button/Secondary', 'fukku/Button/Link', 'fukku/Button/Icon', 'fukku/Button/Ghost'...
 */
export function Button<T>(props: ButtonProps<T>) {
	const {
		border = true,
		buttonAttributes,
		children,
		className = '',
		'data-testid': dataTestId,
		disabled,
		icon,
		iconPressed,
		id,
		inverse,
		isLink,
		isSubmit = false,
		label,
		linkAs,
		linkAttributes,
		loading = false,
		onClick = () => {},
		secondary,
		width,
	} = props

	const actualWidth = width !== 'fill'
	const style = {
		width: actualWidth ? width : undefined,
	}
	const preventClick = disabled || loading

	const handleClickButton: React.MouseEventHandler<HTMLButtonElement> = (
		e
	): void => {
		if (preventClick || !isSubmit) {
			e.preventDefault()
			e.stopPropagation()
		}

		if (!preventClick) {
			onClick(e)
		}
	}

	const classNames = cx(
		styles.button,
		applyStylesIf(!!secondary, styles.secondary),
		applyStylesIf(!!disabled, styles.disabled),
		applyStylesIf(loading, styles.loading),
		applyStylesIf(!children, styles.iconButton),
		applyStylesIf(!!isLink, styles.link),
		applyStylesIf(!!inverse, styles.inverse),
		className,
		applyStylesIf(!!secondary && !border, styles.noBorder),
		applyStylesIf(width === 'fill', styles.fill)
	)

	if (isLink) {
		return (
			<ButtonLink
				className={classNames}
				linkAs={linkAs}
				style={style}
				dataTestId={dataTestId}
				preventClick={preventClick}
				onClick={onClick}
				icon={icon}
				id={id}
				linkAttributes={linkAttributes}
				disabled={disabled}
				label={label}
				iconPressed={iconPressed}
				secondary={secondary}
			>
				{children}
			</ButtonLink>
		)
	}

	return (
		<button
			className={classNames}
			style={style}
			data-testid={dataTestId}
			disabled={disabled}
			id={id}
			onClick={handleClickButton}
			aria-disabled={disabled}
			type={isSubmit ? ButtonType.SUBMIT : ButtonType.BUTTON}
			aria-label={label}
			{...buttonAttributes}
		>
			{icon && (
				<IconButton
					icon={icon}
					iconPressed={iconPressed}
					secondary={secondary}
				/>
			)}
			{children && <span className={styles.content}>{children}</span>}
		</button>
	)
}

// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconFavoffLSmall = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='favoff-l-small'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 16 15'
		>
			<path d='m8 15.5-.337-.333c-.674-.665-1.263-1.248-1.937-1.83C2.611 10.424 0 7.928 0 4.933 0 2.603 1.6.689 3.621.523 5.305.356 6.737 1.105 8 2.686c1.263-1.58 2.695-2.33 4.379-2.163C14.484.689 16 2.603 16 4.933c0 2.995-2.695 5.491-5.726 8.404-.674.582-1.263 1.248-1.937 1.83zM4.042 1.355h-.337C2.105 1.52.842 3.019.842 4.933c0 2.662 2.526 5.075 5.474 7.821.59.5 1.095 1.082 1.684 1.581.59-.582 1.095-1.082 1.684-1.58 2.948-2.747 5.474-5.16 5.474-7.822 0-1.914-1.263-3.412-2.863-3.578-1.432-.167-2.78.582-3.958 2.246l-.337.5-.337-.5C6.568 2.104 5.39 1.355 4.043 1.355' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}

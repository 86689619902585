import { IconArrowMoveAi } from 'icons/components/IconArrowMoveAi'
import { IconBackspace } from 'icons/components/IconBackspace'
import { IconBagL } from 'icons/components/IconBagL'
import { IconBagS } from 'icons/components/IconBagS'
import { IconCloseBig } from 'icons/components/IconCloseBig'
import { IconCloseMedium } from 'icons/components/IconCloseMedium'
import { IconDevolucion } from 'icons/components/IconDevolucion'
import { IconDone } from 'icons/components/IconDone'
import { IconDropdownNextSmall } from 'icons/components/IconDropdownNextSmall'
import { IconDropdownPrevSmall } from 'icons/components/IconDropdownPrevSmall'
import { IconErrorL } from 'icons/components/IconErrorL'
import { IconEyeoffL } from 'icons/components/IconEyeoffL'
import { IconEyeonL } from 'icons/components/IconEyeonL'
import { IconFavoffL } from 'icons/components/IconFavoffL'
import { IconFavoffLSmall } from 'icons/components/IconFavoffLSmall'
import { IconFavoffS } from 'icons/components/IconFavoffS'
import { IconFavoffSSmall } from 'icons/components/IconFavoffSSmall'
import { IconFilter } from 'icons/components/IconFilter'
import { IconFluidL } from 'icons/components/IconFluidL'
import { IconFluidS } from 'icons/components/IconFluidS'
import { IconHamburger } from 'icons/components/IconHamburger'
import { IconHomeL } from 'icons/components/IconHomeL'
import { IconInfoL } from 'icons/components/IconInfoL'
import { IconMore } from 'icons/components/IconMore'
import { IconNationalityS } from 'icons/components/IconNationalityS'
import { IconPauseL } from 'icons/components/IconPauseL'
import { IconPauseS } from 'icons/components/IconPauseS'
import { IconPerfVerTodo } from 'icons/components/IconPerfVerTodo'
import { IconPlayS } from 'icons/components/IconPlayS'
import { IconPlusSmall } from 'icons/components/IconPlusSmall'
import { IconProductS } from 'icons/components/IconProductS'
import { IconQr } from 'icons/components/IconQr'
import { IconSettingsL } from 'icons/components/IconSettingsL'
import { IconSingle } from 'icons/components/IconSingle'
import { IconSquare02L } from 'icons/components/IconSquare02L'
import { IconTagL } from 'icons/components/IconTagL'
import { IconTrashL } from 'icons/components/IconTrashL'
import { IconUpLarge } from 'icons/components/IconUpLarge'
import { IconWalletL } from 'icons/components/IconWalletL'
import { type IconProps } from 'icons/types/Icon.types'
import { type IconName } from 'icons/types/IconName.types'
import { cx } from 'utils/cx'

import styles from './Button.module.scss'

type allowedIcons =
	| 'bag-l'
	| 'bag-s'
	| 'close-big'
	| 'close-medium'
	| 'done'
	| 'dropdown-next-small'
	| 'dropdown-prev-small'
	| 'eyeoff-l'
	| 'eyeon-l'
	| 'favoff-l-small'
	| 'favoff-l'
	| 'favoff-s-small'
	| 'favoff-s'
	| 'filter'
	| 'fluid-l'
	| 'fluid-s'
	| 'hamburger'
	| 'home-l'
	| 'info-l'
	| 'nationality-s'
	| 'pause-l'
	| 'pause-s'
	| 'perf-ver-todo'
	| 'play-s'
	| 'product-s'
	| 'settings-l'
	| 'single'
	| 'square02-l'
	| 'tag-l'
	| 'trash-l'
	| 'up-large'
	| 'wallet-l'
	| 'qr'
	| 'more'
	| 'plus-small'
	| 'arrow-move-ai'
	| 'backspace'
	| 'devolucion'
	| 'error-l'

export type IconButtons = Extract<IconName, allowedIcons>

interface IconButtonProps {
	iconPressed?: IconButtons
	icon: IconButtons
	secondary?: boolean
}

// Imports only used icons. If you need a new icon, add it to the allowedIcons type
// and add it to the availableIcons object.
function getIconComponent(icon: IconButtons): React.ComponentType<IconProps> {
	const availableIcons: Record<IconButtons, React.ComponentType<IconProps>> = {
		'bag-l': IconBagL,
		'bag-s': IconBagS,
		'close-big': IconCloseBig,
		'close-medium': IconCloseMedium,
		'dropdown-next-small': IconDropdownNextSmall,
		'dropdown-prev-small': IconDropdownPrevSmall,
		'eyeoff-l': IconEyeoffL,
		'eyeon-l': IconEyeonL,
		'favoff-l-small': IconFavoffLSmall,
		'favoff-l': IconFavoffL,
		'favoff-s-small': IconFavoffSSmall,
		'favoff-s': IconFavoffS,
		'fluid-l': IconFluidL,
		'fluid-s': IconFluidS,
		'home-l': IconHomeL,
		'info-l': IconInfoL,
		'nationality-s': IconNationalityS,
		'pause-l': IconPauseL,
		'pause-s': IconPauseS,
		'perf-ver-todo': IconPerfVerTodo,
		'play-s': IconPlayS,
		'product-s': IconProductS,
		'settings-l': IconSettingsL,
		'square02-l': IconSquare02L,
		'tag-l': IconTagL,
		'trash-l': IconTrashL,
		'up-large': IconUpLarge,
		'wallet-l': IconWalletL,
		'plus-small': IconPlusSmall,
		'arrow-move-ai': IconArrowMoveAi,
		'error-l': IconErrorL,
		backspace: IconBackspace,
		devolucion: IconDevolucion,
		qr: IconQr,
		done: IconDone,
		filter: IconFilter,
		hamburger: IconHamburger,
		single: IconSingle,
		more: IconMore,
	}
	return availableIcons[icon]
}

export const IconButton = ({
	iconPressed,
	icon,
	secondary,
}: IconButtonProps): React.ReactNode => {
	const IconComponent = getIconComponent(icon)
	let IconComponentPressed
	if (iconPressed) {
		IconComponentPressed = getIconComponent(iconPressed)
	}
	return (
		<>
			<span
				className={cx(styles.buttonIcon, iconPressed && styles.notActiveIcon)}
			>
				<IconComponent color={secondary ? 'content-1' : 'content-inverse'} />
			</span>
			{IconComponentPressed && (
				<span className={cx(styles.buttonIcon, styles.activeIcon)}>
					<IconComponentPressed
						color={secondary ? 'content-1' : 'content-inverse'}
					/>
				</span>
			)}
		</>
	)
}

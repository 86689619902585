// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconSettingsL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='settings-l'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M14.5 21h-5v-2.482l-.34-.142-1.756 1.756-3.536-3.536 1.756-1.755-.142-.341H3v-5h2.482l.142-.34-1.756-1.756 3.536-3.536L9.16 5.624l.341-.142V3h5v2.482l.34.142 1.756-1.756 3.536 3.536-1.756 1.755.142.341H21v5h-2.482l-.142.34 1.756 1.756-3.536 3.536-1.755-1.756-.341.142zm-4-1h3v-2.15l1.575-.654 1.52 1.522 2.123-2.122-1.522-1.52.654-1.576H20v-3h-2.15l-.654-1.575 1.522-1.52-2.122-2.123-1.52 1.522L13.5 6.15V4h-3v2.15l-1.575.654-1.52-1.522-2.123 2.122 1.522 1.52L6.15 10.5H4v3h2.15l.654 1.575-1.522 1.52 2.122 2.123 1.52-1.522 1.576.654zm1.5-4c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4m0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}

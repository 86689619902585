// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconQr = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='qr'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M22 22h-6v-1h5v-5h1zM8 22H2v-6h1v5h5z' />
			<path d='M11 19H5v-6h6zm-5-1h4v-4H6zm9-5h-2v2h2zm4 0h-2v2h2zm0 4h-2v2h2zm-4 0h-2v2h2zm2-2h-2v2h2z' />
			<path d='M9 15H7v2h2zm13-7h-1V3h-5V2h6z' />
			<path d='M19 11h-6V5h6zm-5-1h4V6h-4z' />
			<path d='M17 7h-2v2h2zM3 8H2V2h6v1H3z' />
			<path d='M11 11H5V5h6zm-5-1h4V6H6z' />
			<path d='M9 7H7v2h2z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}

// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconTagL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='tag-l'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M3 3v7.5l.025.232L13.5 21.207l7.707-7.707L10.707 3zm10.5 16.793-9.5-9.5V4h6.293l9.5 9.5z' />
			<path d='M7.5 5C6.121 5 5 6.121 5 7.5S6.121 10 7.5 10 10 8.879 10 7.5 8.879 5 7.5 5m0 4C6.673 9 6 8.327 6 7.5S6.673 6 7.5 6 9 6.673 9 7.5 8.327 9 7.5 9' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}

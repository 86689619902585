// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconFavoffSSmall = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='favoff-s-small'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 16 15'
		>
			<path d='M12.379.525C10.695.358 9.263 1.107 8 2.688 6.737 1.024 5.305.358 3.621.525 1.6.69 0 2.605 0 4.935c0 2.994 2.695 5.49 5.726 8.402.674.582 1.263 1.248 1.937 1.83L8 15.5l.337-.333c.674-.665 1.263-1.248 1.937-1.83C13.39 10.425 16 7.929 16 4.934c0-2.33-1.6-4.243-3.621-4.41' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}

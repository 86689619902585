// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconMore = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='more'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M5 10a2 2 0 0 1 0 3.998h-.003L4.993 14c-1.102 0-1.999-.897-1.999-2s.897-2 2-2l.003.001zm7 0-.003.001h-.004A2 2 0 0 0 9.994 12c0 1.103.897 2 2 2l.003-.001h.004A2 2 0 0 0 12 10m7 0-.003.001h-.004A2 2 0 0 0 16.994 12c0 1.103.897 2 2 2l.003-.001h.004A2 2 0 0 0 19 10' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}

// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconFavoffS = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='favoff-s'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M17.2 3c-2-.2-3.7.7-5.2 2.6-1.5-2-3.2-2.8-5.2-2.6-2.4.2-4.3 2.5-4.3 5.3 0 3.6 3.2 6.6 6.8 10.1.8.7 1.5 1.5 2.3 2.2l.4.4.4-.4c.8-.8 1.5-1.5 2.3-2.2 3.7-3.5 6.8-6.5 6.8-10.1 0-2.8-1.9-5.1-4.3-5.3' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
